<template>
    <div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <EpisodeHeader
                :episodes="episodes"
                :episode="computedEpisode"
                :tab="'ACTION_PLAN'"
                @episode-changed="episodeChanged"
            />
        </div>
        <div class="mt-5 col-span-2 bg-white-pure l-box-shadow rounded px-4 py-3">
            <div class="flex flex-row justify-between pb-4 items-center">
                <h4 class="font-display font-semibold text-left text-lg">Goals</h4>
            </div>
            <div class="flex items-center mb-4">
                <div class="mr-2">
                    <fa-icon icon="filter" class="text-sm text-black" />
                </div>
                <v-checkbox
                    hide-details
                    class="mt-0 mr-4"
                    :ripple="false"
                    v-model="showCurrent"
                    label="Current Goals"
                    @change="clearFilters"
                ></v-checkbox>
            </div>
            <VocEdGoalsTable
                :tableData="vocationalEd"
                :users="users"
                :episode="computedEpisode"
                :isAddable="$can('add', 'action_plan')"
                :isEditable="$can('edit', 'action_plan')"
                @table-data-change="tableDataUpdated"
                @vocational-ed-saved="actionVocationalEdUpdated"
            />
        </div>
    </div>
</template>

<script>
import camelcaseKeys from 'camelcase-keys'
import GET_EPISODES_FOR_CLIENT from '@/graphql/queries/getEpisodesForClient.gql'
import { mapState } from 'vuex'
import EpisodeHeader from '@/components/episodes/partials/EpisodeHeader'
import VocEdGoalsTable from '@/components/voc-ed-goals/partial/VocEdGoalsTable.vue'
import GET_VOCATIONAL_ED from "@/graphql/queries/getVocationalEdForEpisode.gql"
import FileMethods from '@/components/shared/mixins/fileMethods'
import MessageDialog from '@/components/shared/mixins/messageDialog'
import GET_USERS from '@/graphql/queries/getUsers.gql'

export default {
    name: 'VocEdGoals',
    components: { VocEdGoalsTable, EpisodeHeader },
    mixins: [ FileMethods, MessageDialog ],
    apollo: {
        episodes: {
            query: GET_EPISODES_FOR_CLIENT,
            variables() {
                return {
                    clientId: Number(this.clientId)
                }
            },
            skip() {
                return this.clientId === null
            },
            update: ({ episodes }) => {
                const openEpisodes = episodes.filter(e => e && e.status === 'OPEN')
                const otherEpisodes = episodes.filter(e => e && e.status !== 'OPEN')
                return camelcaseKeys([...openEpisodes, ...otherEpisodes], {deep: true})
            },
        },
        vocationalEd: {
            query: GET_VOCATIONAL_ED,
            variables() {
                const variables = {episodeId: Number(this.currentEpisode.episodeId)}
                if (this.showCurrent) {
                    variables.relevantCompare = {_eq: this.showCurrent}
                }
                return variables
            },
            skip() {
                return this.currentEpisode == null || this.currentEpisode.episodeId == null
            },
            result({ data: { vocationalEd } }) {
                let convertedVocationalEd = camelcaseKeys(vocationalEd, {deep: true})

                this.vocationalEd = [...convertedVocationalEd]
                console.log("vocationalEd", vocationalEd);
            },
            watchLoading (isLoading, countModifier) {
                this.loading = isLoading
            }
        },
        users: {
            query: GET_USERS,
            update: ({ users }) => {
                return camelcaseKeys(users, {deep: true})
            },
        }
    },
    data() {
        return {
            clientId: this.$route.params.clientId,
            episodes: [],
            vocationalEd: null,
            users: [],
            defaultVocationalEd: {
                order: null,
                goal: null,
                action: null,
                options: null,
                commenced: null,
                completed: null,
                rating: null,
                timeFrame: null
            },
            episode: {},
            showCurrent: true
        }
    },
    mounted() {
        this.$store.commit('SET_CLIENT_ID', this.clientId)
    },
    methods: {
        async episodeChanged(episode) {
            this.$store.commit('SET_CURRENT_EPISODE', episode)
            this.episode = {...episode}
            await this.actionVocationalEdUpdated()
        },
        createVocationalEd() {
            const vocationalEd = {...this.defaultVocationalEd}
            this.vocationalEd.push({
                ...vocationalEd
            })
        },
        async clearFilters() {
            // if none of the filters has a value, set to null since that's the only way to get every case note
            if (!this.showCurrent) this.showCurrent = null
            await this.actionVocationalEdUpdated()
        },
        tableDataUpdated(vocationalEd) {
            this.vocationalEd = vocationalEd
        },
        async actionVocationalEdUpdated() {
            await this.$apollo.queries.vocationalEd.refetch()
        },
    },
    computed: {
        ...mapState({
            currentEpisode: state => state.app.currentEpisode
        }),
        computedEpisode() {
            return this.currentEpisode || this.episode
        },
    },
    watch: {
        '$route.path': function(val, oldVal){
            this.$apollo.queries.client.refetch()
        }
    }
}
</script>
