<template>
    <div>
        <component
            :is="renderForm"
            :model="isValid"
            :rules="rules"
            ref="formInput"
            class="c-editable-cell"
            @submit.prevent=""
        >
            <component
                :is="renderEditableComponent"
                v-show="editActive"
                ref="input"
                :dense="true"
                :single-line="true"
                :hide-details="true"
                :outlined="false"
                :rules="isRequired ? [required] : []"
                v-model="formInput.inputValue"
                :label="label"
                class="p-0 m-0"
                :items="items"
                :trigger-on-focus="false"
                @select="handleSelect"
                @keyup="handleChange"
            >
            </component>
        </component>
        <a
            @click="cellClickHandler"
            v-show="!editActive"
            class="justify-start py-1 m-0 hover:text-grey-1 text-grey-2 leading-normal border border-transparent border-l-0 border-r-0 border-t-0"
            :v-html="!linkText ? '&nbsp;': ''"
            :class="{'w-4/5' : !linkText, 'fieldEmpty': !formInput.inputValue, 'requiredField': this.isRequired}"
        >
            {{ linkText }}
        </a>
    </div>
</template>

<script>
export default {
    name: 'VAutocompleteCell',
    props: {
        value: {
            type: [String, Object],
            default: ''
        },
        displayValue: {
            type: String,
            default: ''
        },
        cellId: {
            type: [String, Number],
            default: null
        },
        editActive: {
            type: Boolean,
            default: null
        },
        isRequired: {
            type: Boolean,
            default: false
        },
        items:{
            type: Array,
            default: () => []
        },
        label: {
            type: String,
            default:''
        },
        defaultValue: {
            type: String,
            default: ''
        },
        isCombobox: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isValid: true,
            focused: false,
            formInput: {inputValue: ''},
            rules: {
                inputValue: [
                    { required: this.isRequired, message: 'Required field', trigger: 'blur' },
                ],
            },
        }
    },
    mounted() {
        this.formInput.inputValue = this.value || this.defaultValue
    },
    methods: {
        cellClickHandler() {
            this.focused = true
            this.$emit('edit-row', this.cellId)
        },
        validate(){
            if (this.$refs.formInput) return this.$refs.formInput.validate()
        },
        handleSelect(item){
            this.formInput.inputValue = item.registrationNum
            this.$emit('select', item)
        },
        clearValidate(){
            return this.$refs.formInput.clearValidate()
        },
        handleChange(event) {
            this.formInput.inputValue = event.target.value
        }
    },
    computed: {
        linkText(){
            let text =  this.displayValue || this.value || this.defaultValue
            return text || ''
        },
        renderEditableComponent() {
            if (this.editActive && this.isCombobox) return 'v-combobox'
            if (this.editActive && !this.isCombobox) return 'v-autocomplete'
            return ''
        },
        renderForm() {
            if (this.editActive) return 'v-form'
            return ''
        },
    },
    watch: {
        value() {
            if (this.value) this.formInput.inputValue = this.value
        },
        'formInput.inputValue': function() {
            if (this.editActive) {
                this.$emit('input', this.formInput.inputValue)
                if (this.emitChange) {
                    this.$emit('change', true)
                }
            }
        },
    },
}
</script>
